export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const lowercaseFirstLetter = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toLowerCase() + s.slice(1)
}

/**
 * Supprime les caractères diacritiques d'un string
 * (accents et caractères spéciaux)
 * @param { string } str
 * @returns { string } str sans caractères diacritiques
 */
export const removeDiacritics = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export const toIntFixed = (value) => {
  return parseInt(parseFloat('' + value * 100).toFixed(2));
};

export const toFloatFixed = (value) => {
  return parseFloat('' + value / 100).toFixed(2);
};

export const floatToFixed = (value, nb) => {
  return parseFloat('' + value).toFixed(nb);
};

export const isEmpty = (value) => {
  return value === '' || value === null;
};

export const toClipboard = (text) => {
  return navigator.clipboard.writeText(text);
}
