import {_get, clubId} from "@api/doinsport/services/httpService";

export const ACTIVITIES_URL = "/activities";

export const getAllActivities = (itemsPerPage = 500, page = 1, filter = '') => {
  return _get(ACTIVITIES_URL
    + '?name='
    + filter
    + '&itemsPerPage=' + itemsPerPage
    + '&partial=true'
    + '&page=' + page
  );
}

export const getActivity = (iriActivity) => _get(iriActivity);

export const getCustomActivities = (itemsPerPage = 500, page = 1, search = '') =>
  _get(
    ACTIVITIES_URL
    + '?itemsPerPage=' + itemsPerPage
    + '&partial=true'
    + '&page=' + page
    + '&name=' + search
    + '&club.id=' + clubId
  )
;
